export default {
    permissions: 'Permissions',
    values: {
        team: 'Team',
        roles: 'Roles',
        members: 'Members',
        reports: 'Reports',
        policiesEnrollmentsAndDevices:
            'Policies, enrollment tokens and devices',
        policies: 'Policies',
        enrollments: 'Enrollment tokens',
        devices: 'Device commands',
        devicePossession: 'Device possession',
        webhooks: 'Webhooks',
        billing: 'Billing',
        accessTokens: 'Access tokens',
        reseller: {
            reseller: 'Reseller',
            teams: 'Teams',
            users: 'Users',
            usersActions: 'Actions on users',
            billing: "Team's billing management"
        }
    },
    youNeedViewDevices:
        'You need the view devices permission to manage devices commands',
    possessionStateYouNeedViewDevices:
        'You need the view devices permission to manage device possession state',
    youNeedViewEnrollment:
        'You need the view enrollment tokens permission to manage enrollment tokens',
    youNeedViewPolicies:
        'You need the view policies permission to manage policies',
    youNeedRolePermissions:
        'You need the view roles permission to select any of members',
    youNeedViewUsers:
        'You need the view users permission to enable any user actions',
    noAccess: 'No access',
    youNeedViewTeamsPermissionForBillingAdmin:
        'You need the view teams permission to be able to manage their billing'
};
