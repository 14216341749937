export default {
    isBillingPage:
        "There's 1 team with a broken Stripe subscription. You can check which plans are broken below and either fix or delete them. | There's {count} teams with a broken Stripe subscription. You can check which plans are broken below and either fix or delete them.",
    hasBillingAdminPermission: {
        p1: "There's 1 team with a broken Stripe subscription. Please take action in your | There's {count} teams with a broken Stripe subscription. Please take action in your",
        link: 'billing page',
        p2: 'as soon as possible, since this affects the team directly. | as soon as possible, since this affects the teams directly.'
    },
    notHasBillingAdminPermission:
        "There's 1 team with a broken Stripe subscription. Please contact a billing administrator, so they can fix it as soon as possible since this affects the team directly. | There's {count} team with a broken Stripe subscription. Please contact a billing administrator, so they can fix it as soon as possible since this affects teams directly.",
    hasTeamsPagePermission: {
        p1: 'Checkout the',
        link: 'broken teams in your teams page',
        p2: 'to learn more about the affected teams.'
    },
    articleLink: {
        p1: 'Learn more about how to amend broken plans in our',
        link: 'support page article',
        p2: '.'
    }
};
