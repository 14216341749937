import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import BooleanSelect from '@/components/form/select/BooleanSelect.vue';
import NumericInput from '@/components/form/input/NumericInput.vue';
import ReversedCheckbox from '@/components/form/checkbox/ReversedCheckbox.vue';
import FormulateInputAutocomplete from '@/components/form/select/FormulateInputAutocomplete.vue';
import FormulateInputDate from '@/components/form/date/FormulateInputDate.vue';

export const VueFormulateOptions = {
    library: {
        booleanSelect: {
            classification: 'select',
            component: 'BooleanSelect',
            slotProps: {
                component: ['allowUndefined']
            }
        },
        number: {
            classification: 'text',
            component: NumericInput
        },
        reversedCheckbox: {
            classification: 'box',
            component: ReversedCheckbox
        },
        autocomplete: {
            component: FormulateInputAutocomplete,
            slotProps: {
                component: ['borderless', 'disabled', 'fetch', 'placeholder']
            }
        },
        date: {
            component: FormulateInputDate,
            slotProps: {
                component: ['range', 'noDropdown', 'noYear', 'allowedDates']
            }
        }
    },
    mimes: {
        csv: 'text/csv',
        csv_excel: 'application/vnd.ms-excel',
        text: 'text/plain'
    }
};

Vue.component('NumericInput', NumericInput);
Vue.component('BooleanSelect', BooleanSelect);
Vue.component('ReversedCheckbox', ReversedCheckbox);
Vue.use(VueFormulate, VueFormulateOptions);
