import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';

// Authentication middleware
export default (_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
    const redirect = next;

    // If not authenticated on load redirect to login
    if (!store.getters['auth/isAuthenticated']) {
        return redirect('/auth/login');
    }
    next();
};
