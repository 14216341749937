import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';
import env from '@/plugins/env';

// Superadmin middleware
export default (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
    // Get required data for check
    const redirect = next;
    const { user } = store.state.user;
    const { SUPERADMINS: admins } = env;

    // Do check
    const userIsSuperadmin = admins.find(
        (admin) => admin === user.details.email
    );

    // Redirect if needed
    if (!userIsSuperadmin) {
        return redirect('404');
    }

    next();
};
