export default {
    title: 'Team settings',
    branding: {
        title: 'Branding',
        notBrandingFeature:
            'Your plan does not allow setting custom branding, you can change plans in'
    },
    security: 'Security',
    androidManagementBinding: 'Android Management Binding',
    androidEnterpriseIsConnected: 'Android Enterprise is connected',
    resellertitle: 'Reseller settings',
    readonlyTitle: 'Team information',
    zeroTouchIdHelp: {
        p1: "To update your team's zero-touch ID, please visit our",
        p2: 'to contact us.'
    },
    trialPeriod: {
        title: 'Setup trial period',
        missingPlans: {
            p1: 'To set up a trial period you first need to have plans, you add them in your',
            p2: 'page.'
        }
    },
    brandingFeature:
        'Want some style customization? Our branding feature allows you to change the logo and color!'
};
