export default {
    title: 'Teams',
    item: 'team | teams',
    searchFields: 'name',
    text: {
        p1: 'You can create a new team using a unique slug for it.',
        preferredTeam1: 'You can set your preferred team in your',
        profilePage: 'profile page',
        preferredTeam2: '.'
    },
    addDialog: {
        title: 'Create team'
    },
    deleteDialog: {
        title: 'Leave team',
        text: {
            p1: 'Are you sure you want to leave the team',
            p2: "? This action is not reversible, and you'll have to request to join this team again if you change your mind."
        }
    },
    error_add_400: {
        isReseller: {
            p1: 'Please set up some plans on',
            p2: 'before creating a team.'
        },
        isNotReseller: 'Reseller is not allowing team registrations right now.'
    },
    error_add_403: 'Slug is not allowed, please set a different one.',
    error_add_409: 'Slug is in use, please set a different one.',
    error_delete: "We couldn't remove you from the team, please try again.",
    noData: 'You have no teams',
    matchToDelete: 'I want to leave {0}',
    trial: {
        p1: 'This team will be benefitting from a',
        p2: 'trial period.'
    }
};
