export default {
    title: 'Billing',
    menuItems: {
        generateDefaultPlans: 'Generate default plans',
        importPlansFrom: 'Import plans from Stripe'
    },
    changePlanCopy: {
        upgrade: 'Upgrade subscription',
        change: 'Change subscription',
        set: 'Set subscription'
    },
    // Non-resellers
    text: 'You can change payment method, billing information and see your subscription history on your portal.',
    subtitle: 'Current subscription',
    currentPlanName: '{0} plan',
    pricingPerDevice: '{0} per device / month',
    supportFreeDevicesText: {
        p1: 'Your plan includes up to',
        p2: '{0} devices',
        p3: 'free.',
        p4: "Once you surpass that amount, your plan's pricing will apply to all your enrolled devices."
    },
    changePlanDialog: {
        title: 'Change subscription',
        teamIsOnTrial:
            'Changing your plan while in a trial period will cause your team to end the trial. Please proceed with caution.'
    },
    // Resellers
    reseller: {
        subtitle: 'Billing plans',
        youHaveNotAdded: 'You have not added any plans',
        editDialog: {
            title: 'Configure {0} plan'
        },
        fixBrokenDialog: {
            title: 'Fix {0} plan',
            text: 'This plan is considered broken due to having a mismatch in the Stripe price ID. Please update it below.'
        },
        deleteDialog: {
            title: 'Delete {0} plan',
            isSignupPlan:
                "A signup plan can't be deleted, please set a different one in your signup configuration if you want to proceed.",
            text: 'If you proceed in deleting this plan, any teams that were subscribed to it will switch to your signup plan.'
        },
        matchToDelete: 'Delete {0}',
        freeDevicesHelp: `Customers won't be charged up to this number of devices`,
        errorUpdate: {
            reverted: {
                p1: 'Stripe was not able to update the plan, please try again later. If this error persists, please visit our',
                p2: 'and contact us there.'
            },
            revertedFail: {
                p1: 'Stripe was not able to apply the change to all teams under this plan, please visit our ',
                p2: ' and contact us to have this reviewed.'
            },
            getInTouch: 'please get in touch with a Mambo administrator',
            timeout: {
                p1: 'There was an error while updating your plan, please try again. If this error persists, please visit our',
                p2: 'and contact us there.'
            },
            default: 'There was an error updating your plan, please try again.'
        },
        signupPlan: 'Signup Plan',
        signupPlanNeedsActive:
            'Signup plans need to be active, to deactivate this plan please set another one as the signup plan.'
    },
    // Signup configuration for resellers
    signupConfiguration: {
        title: 'Signup configuration',
        manage: 'Configure signups',
        noPlans:
            'You first need to create some plans to be able to configure signups.',
        trialSet: {
            p1: 'You have a',
            p2: 'trial period set that uses the',
            p3: 'plan. All new teams that signup will be benefiting from this trial.'
        },
        trialUnset: {
            p1: 'You have the',
            p2: 'plan set as the signup plan to be used for new teams with',
            p3: 'no trial period',
            p4: 'at this moment.'
        },
        trialPeriodDaysChangeAlert:
            'Changing the trial period length will affect teams that are currently on trial, their trial will extend / reduce depending on the change.',
        settingTrialAlert:
            'Only newly created teams will benefit from this trial.',
        signupPlanChangeAlert:
            'Changing the signup plan will affect teams that are currently on trial, their plan will be automatically changed to the newly selected one.'
    },
    fixCancelledSubscription:
        "There's been an issue with your subscription and it has been cancelled, you can remedy this by setting a new one.",
    fixEmptySubscription:
        "There's been an issue with your subscription and you have none set, you can remedy this by setting a new one.",
    teamIsPendingDelete:
        'Your team is in the process of being deleted, so it has a cancelled subscription.',
    planIsBroken: 'This plan is considered broken'
};
