export default {
    gender: {
        male: 'Male',
        female: 'Female',
        nonbinary: 'Non-binary',
        unknown: 'Prefer not to say'
    },
    possessionState: {
        IN_POSSESSION: 'In Possession',
        LOST: 'Lost',
        STOLEN: 'Stolen'
    },
    networkSelectOptions: {
        unspecified: 'No network set',
        custom: 'Set custom network'
    },
    reportTypeSelect: {
        basic: 'Basic assets (PDF)',
        detailed: 'Detailed assets (PDF)',
        basicCSV: 'Basic assets (CSV)',
        teamBillingCSV: 'Teams billing report (CSV)'
    }
};
