export default {
    label: {
        kioskCustomLauncherEnabled:
            'Replace home screen with a launcher only displaying configured applications',
        kioskType: 'Kiosk type',
        kioskTypeHelp:
            'Advanced allows full customization of the kiosk launcher',
        group: 'Block device actions for kiosks (Example: power button press)',
        powerButtonActions: 'Power Button',
        systemErrorWarnings: 'System Errors (Example: App not responding)',
        systemNavigation: 'System Navigation',
        statusBar: 'Status Bar',
        deviceSettings: 'Device Settings',
        orientation: 'Orientation',
        backgroundType: 'Background type',
        backgroundColor: 'Background color',
        backgroundImage: 'Update background image',
        messageLocation: 'Message location',
        message: 'Message',
        messageHelp:
            "This will be always in display on the kiosk's home-screen",
        applications: 'Applications',
        layoutSetup: 'Layout definition',
        rows: 'Rows',
        columns: 'Columns',
        pages: 'Pages'
    },

    select: {
        powerButtonActions: {
            unspecified: 'Default',
            available: 'Available',
            blocked: 'Blocked'
        },
        systemErrorWarnings: {
            unspecified: 'Default',
            enabled: 'Displayed',
            muted: 'Not displayed'
        },
        systemNavigation: {
            unspecified: 'Default',
            enabled: 'Allowed',
            disabled: 'Blocked',
            homeButtonOnly: 'Allow home button only'
        },
        statusBar: {
            unspecified: 'Default',
            enabled: 'Enabled',
            disabled: 'Disabled',
            systemInfoOnly: 'Only show system info (Example: Battery)'
        },
        deviceSettings: {
            unspecified: 'Default',
            allowed: 'Enabled',
            blocked: 'Disabled'
        },
        kioskType: {
            native: 'Native',
            advanced: 'Advanced'
        },
        kioskOrientation: {
            auto: 'Auto',
            portrait: 'Portrait',
            landscape: 'Landscape'
        },
        backgroundType: {
            color: 'Color',
            image: 'Image'
        },
        kioskMessageLocation: {
            top: 'Top of home screen',
            bottom: 'Bottom of home screen'
        },
        kioskLayoutSetup: {
            manual: 'Manual',
            auto: 'Automatic'
        }
    },
    layoutDesigner: {
        noAppsSelected:
            'Please select some apps so you can place them on the layout',
        allAppsPlaced: 'You have placed all the apps on the layout'
    },
    someAppsShown: {
        p1: `Only applications that are part of the policy's`,
        application: 'applications',
        p2: 'component will be displayed on the layout.'
    },
    kioskPreview: 'Kiosk preview',
    advancedKioskBeta: 'Advanced kiosk is currently in beta',
    advancedKioskFeature: {
        p1: 'Want more customization? Our Advanced Kiosk allows additional configuration, custom backgrounds, app layouts, and more!',
        p2: 'Check out',
        p3: 'to upgrade your plan.'
    }
};
