export default {
    error: {
        create: {
            p1: `There was an error, please make sure all the owners of this team are using a Google account email and try again. You can check your owner's emails on your`,
            members: 'members',
            p2: 'page.'
        }
    },
    intro: {
        p1: {
            p1: 'Zero-touch enrollment',
            p2: 'allows you to deploy multiple devices without having to manually set each of them.'
        },
        p2: `The applications, configurations and management are set automatically, so the devices can be used directly out of the box. You’ll need to set an account so you can start using zero-touch enrollments.`,
        p3: {
            p1: 'When creating an account, all the owner’s emails will be used.',
            p2: 'Please note that these emails need to be a Google account, otherwise the zero-touch account creation will fail.'
        }
    },
    btn: {
        create: 'Create zero-touch account',
        link: 'Link an existing account'
    },
    loading: {
        creating: 'We are creating the zero-touch account',
        linking: 'We are linking your zero-touch account'
    },
    iframe: {
        setupProvisioningExtras:
            'Creating or linking an account? Setup provisioning extras',
        dpcDetails:
            "These settings will only be applied to the Zero-Touch config if you're creating or linking a Zero-Touch customer account. Please be sure to set them before finishing the account setup."
    }
};
