export default {
    title: 'Sessions',
    item: 'session | sessions',
    logoutDialog: {
        title: 'Logging out',
        text: 'Are you sure you want to log out of this session?'
    },
    error_delete: "We couldn't logout, please try again.",
    bulkLogout: {
        title: 'Logout session | Logout sessions',
        text: {
            p1: 'Are you sure you want to logout',
            p2: 'session? | sessions?'
        }
    },
    currentSession: 'Current session',
    noData: 'You have no sessions',
    alert: {
        impersonate:
            'This is an impersonated session being used by the support team'
    }
};
