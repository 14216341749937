export default {
    profileStep: 'Set up your account',
    teamStep: 'Set up your team',
    teamStepReseller: 'Set up your reseller',
    profile: 'Profile',
    twoFactor: 'Two-factor authentication',
    twoFactorText:
        "2FA adds an additional layer of protection in your account. You'll need to have a TOTP app like Google Authenticator or a password manager like 1Password to use 2FA.",
    twoFactorEnabled: '2FA has been enabled',
    twoFactorEnabledForTeam:
        '2FA is enforced for {0}, please enable it to continue',
    termsOfService: 'Terms of Service',
    branding: 'Branding',
    reseller: 'Reseller',
    slugAlreadyExists: 'Slug already exists, please choose a unique slug.',
    logoIsRequired: 'Reseller logo is required.',
    amapi: 'Android Management binding',
    connect: 'Connect',
    androidManagament: 'Android Management',
    resellerNoBilling: 'Reseller is not accepting new teams at this time.',
    generalError:
        'There was an error completing your onboarding, please try again.',
    impersonated:
        'Team setup for {teamName} has not been completed, only a team owner is allowed to continue this process.',
    qrDialog: {
        title: 'Enable two-factor authentication',
        text: 'Scan this QR code in your authenticator app and enter the one-time password (OTP).',
        error: 'Invalid one-time password, please try again.',
        genericError:
            'An error occured while verifying one-time password, please try again'
    },
    backupCodesDialog: {
        title: 'Backup codes',
        text: 'In case you lose access to your authenticator device, you can use these backup codes to login. Make sure you keep them someplace safe.',
        youWontSeeAgain: "You won't see these backup codes again."
    },
    genderOptions: {
        male: 'Male',
        female: 'Female',
        nonbinary: 'Non-binary',
        unknown: 'Prefer not to say'
    },
    newTeam: {
        p1: 'Your new team',
        p2: 'is almost ready to use!'
    },
    trial: {
        p1: 'Complete the setup to kick off a',
        p2: 'trial period.'
    },
    startTrial: 'Start trial',
    enterpriseBinding: {
        readOnly:
            'Your team has been set to read-only. You cannot perform any binding actions in this mode.',
        notOwner:
            "Your team has no valid enterprise binding, please reach out to the team's owners to re-bind the team.",
        title: 'Android Enterprise Binding',
        button: 'Bind Android Enterprise',
        success: {
            connected: 'Your Android Enterprise is connected.'
        },
        error: {
            generic:
                'There was an error while binding your AMAPI enterprise, please try again.',
            teamAlreadyConnected:
                'This team already has an AMAPI enterprise connected.',
            enterpriseBoundToAnotherTeam:
                'This AMAPI enterprise is already bound to a different team. Please try to bind again using another Google account.'
        }
    }
};
