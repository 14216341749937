export default {
    networkConfigurations: 'Device WiFi networks',
    networkConfigurationsItem: 'network | networks',
    itemSettings: '{0} settings',
    excludedHosts: 'Excluded hosts from proxy',
    excludedHostsItem: 'exclusion | exclusions',
    oncCertificateProviders: {
        title: 'ONC certificate providers',
        itemText: 'provider | providers'
    },
    options: {
        locationMode: {
            unspecified: 'Default',
            userChoice: 'User Choice',
            enforced: 'Location Enforced',
            disabled: 'No location'
        },
        preferentialNetworkService: {
            unspecified: 'Default',
            enabled: 'Enabled',
            disabled: 'Disabled'
        }
    },
    label: {
        // nested - Network
        // start of group 1
        groupName: 'Details',
        Name: 'Network name',
        SSID: 'SSID',
        // end of group 1
        // start of group 2
        groupSecurity: 'Security',
        Security: 'Security',
        Passphrase: 'Password',
        // start of group 3
        groupIdentity: 'EAP Configuration',
        Identity: 'Identity',
        Outer: 'Outer',
        Inner: 'Inner',
        ClientCertRef: 'Base64 PKCS12 Client Certificate',
        X509: 'Base64 X509 Server CA Certificate',
        // end of group 3
        // end of group 2
        AutoConnect: 'Autoconnect Network',
        // More fields
        // start of group 4
        groupHost: 'Default network proxy configuration on device',
        proxyConfig: 'Proxy config',
        host: 'Host',
        port: 'Port',
        pacUri: 'PAC script URL',
        // nested - Exclusions
        excludedHosts: 'Excluded host',
        // end of group 4
        // start of group 5
        groupPackageName: 'Global VPN configuration on device',
        packageName: 'VPN Package',
        lockdownEnabled: "Block networking if VPN isn't connected",
        // end of group 5
        locationMode: 'Default location mode on device',
        bluetoothContactSharingDisabled:
            'Enable bluetooth contact sharing on device',
        bluetoothConfigDisabled: 'Enable bluetooth configuration on device',
        cellBroadcastsConfigDisabled:
            'Enable cell broadcast configuration on device',
        mobileNetworksConfigDisabled:
            'Enable mobile network configuration on device',
        tetheringConfigDisabled:
            'Enable tethering/hotspot configuration on device',
        vpnConfigDisabled: 'Enable VPN configuration on device',
        wifiConfigDisabled: 'Enable WiFi configuration on device',
        networkResetDisabled: 'Enable network reset setting on device',
        outgoingBeamDisabled: 'Enable NFC sending data from apps on device',
        outgoingCallsDisabled: 'Enable outgoing phone calls from device',
        shareLocationDisabled: 'Enable location sharing from device',
        smsDisabled: 'Enable outgoing SMS from device',
        dataRoamingDisabled: 'Enable data roaming on device',
        bluetoothDisabled: 'Enable bluetooth on device',
        networkEscapeHatchEnabled:
            'Allow users to temporarily connect to a local WiFi network if no connection found on device boot, only until device policy is updated',
        preferentialNetworkService: 'Preferential network service',
        oncCertificateProviders: {
            main: 'ONC certificate providers',
            certificateReferences: 'Certificate references',
            contentProviderEndpoint: {
                main: 'Content provider endpoint',
                uri: 'URI',
                packageName: 'Package name',
                signingCertsSha256: 'Signing certificates (SHA-256)'
            }
        }
    }
};
