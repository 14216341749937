export default {
    title: 'Passwords &amp; 2FA',
    error_password:
        'There was an error updating your password, please try again.',
    success_password: 'Your password has been changed successfully.',
    twoFactor: {
        title: 'Two-factor authentication',
        text: "2FA adds an additional layer of protection in your account. You'll need to have a TOTP app like Google Authenticator or a password manager like 1Password to use 2FA.",
        enable2fa: 'Enable 2FA'
    },
    twoFactorEnabled: {
        text: '2FA adds an additional layer of protection in your account.',
        youHave: 'You have 2FA enabled'
    },
    error_enable: 'There was an error enabling 2FA, please try again.',
    error_disable: 'There was an error disabling 2FA, please try again.',
    error_regenerate:
        'There was an error regenerating new backup codes, please try again.',
    qrDialog: {
        title: 'Scan QR code',
        text: 'Scan this QR code in your authenticator app and enter the one-time password (OTP).'
    },
    error_verify: 'Invalid one-time password, please try again.',
    error_2fa:
        'An error occured while verifying one-time password, please try again',
    backupDialog: {
        title: 'Backup codes',
        text: 'In case you lose access to your authenticator device, you can use these backup codes to login. Make sure you keep them someplace safe.',
        youWontSee: "You won't see these backup codes again."
    },
    disableDialog: {
        title: 'Disabling 2FA',
        text: 'Are you sure you want to disable two-factor authentication? This will make your account less secure.'
    },
    regenerateDialog: {
        title: 'Regenerating backup codes',
        text1: 'Are you sure you want to regenerate your backup codes?',
        text2: 'The current codes will stop working immediately.'
    },
    wipePassword: {
        title: 'Clear password',
        intro: 'This action entirely removes your current password and will cause you to only be able to sign in using passwordless login.',
        successAlert:
            "An email has been sent to confirm this action. Please follow the email's instructions to proceed."
    }
};
