export default {
    loading: {
        default: 'Loading',
        token: 'We are verifying your link'
    },
    error: {
        default: 'There was an error, please try again.',
        conflict: 'This value is already in use.',
        login: "We couldn't log you in, your credentials are invalid.",
        recover: 'Your link may have expired, to try again you can go back to',
        register: {
            default:
                "We couldn't create you an account, the registration is invalid.",
            weakPassword: 'Chosen password is not secure',
            invalidDomain: 'Invalid registration domain'
        },
        token: 'Your link may be expired, please try again.',
        bindEnterprise: 'Unable to bind enterprise, AMAPI returned error.',
        importPolicies:
            'Unable to import policies, server returned error. Please check your CSV file only has headers "policyAmapiID" and "policyName".',
        devicePolicyUpdate:
            'Unable to update devices, server returned error. Please check your CSV file only has headers "deviceName" and "policyName".',
        zeroTouchGoogleAccounts:
            'An error occured when creating the Zero-touch account, please ensure all owners have a Google account attached to their email address',
        bulkDelete: 'There was an error deleting {0}, please try again.',
        bulkCommands: "We couldn't {0} of {1}, please try again.",
        bulkChangeDevicePolicy:
            "We couldn't update the policy of {0}, please try again.",
        bulkChangeDevicePossessionState:
            'There was an error updating the possession state of {0}, please try again.'
    },
    success: {
        forgot: 'If the given email is in our database, we will send you the link to reset your password.',
        login: {
            p1: 'If this email exists, we will send you an email to login. Please try to',
            p2: "again if you didn't receive it."
        },
        register: {
            title: 'Check your email',
            text: "We've sent you a link to verify your email. Once you've clicked on the link, you'll be able to log in."
        }
    },
    error_fetch: {
        billing:
            "We couldn't get information about your plan, please try again."
    },
    error_change: {
        billing: "We couldn't change your plan right now, please try again.",
        devices: "The policy couldn't be changed, please try again."
    },
    error_update: {
        billing: "Plan couldn't be updated, please try again.",
        configureItem: "We couldn't configure your {0}, please try again.",
        updateItem: "We couldn't update the {0}, please try again."
    },
    error_delete: {
        default: "We couldn't delete, please try again.",
        defaultRemove: "We couldn't remove, please try again.",
        deleteItem: "We couldn't delete the {0}, please try again.",
        removeItem: "We couldn't remove the {0}, please try again."
    },
    error_add: {
        createdItem: "The {0} couln't be created, please try again.",
        addItem: "The {0} couldn't be added, please try again."
    },
    error_add_409: {
        components:
            'A component with that name already exists, please set a different one.',
        policies:
            'A policy with that name already exists, please set a different one.',
        resellers: {
            domain: 'A reseller under that domain already exists, please set a different one.',
            stripe: 'A reseller with that Stripe key already exists, please set a different one.'
        }
    },
    error_duplicate: {
        duplicateItem: "We couldn't duplicate the {0}, please try again."
    },
    error_command: {
        devices: "We couldn't {0}, please try again."
    },
    error_save: {
        teamEnterprise:
            'There was an error updating your enterprise data, please try again.'
    },
    error_possession_state: {
        updateStateError:
            'There was an error updating this possession state, please try again.'
    },
    error_upload_400: {
        default:
            'The CSV is missing some of the required fields, please update your file and try again.'
    }
};
