export default {
    password: 'Secure password',
    name: 'Name',
    url: 'https://',
    newDevicePassword: 'NewDevicePassword',
    describeToken: 'Describe your token',
    googlePlayContactEmail: 'contact@email.com',
    dataProtectionOfficer: 'Officer name',
    dataProtectionOfficerEmail: 'protection@email.com',
    phone: '000000000',
    euRepresentative: 'Representative name',
    euRepresentativeEmail: 'representative@email.com',
    noLanguagesSelected: 'No additional languages selected',
    email: 'email@email.com',
    zeroTouchId: 'Zero Touch ID',
    stripeKey: 'sk_stripekey',
    stripeWebhookSecret: 'whsec_stripeSecret',
    slug: 'team-slug',
    domain: 'Domain',
    currentPassword: 'Your current password',
    newPassword: 'New secure password',
    receiverActivity: 'com.android.app/.MainActivity',
    preferredActivityAction: 'MAIN',
    preferredActivityCategory: 'DEFAULT',
    permittedAppPackage: 'com.android.app',
    frpAdminEmails: 'whitelisted@email.com',
    urlPattern: '*.google.com',
    privateKeyAlias: 'my-private-key',
    packageName: 'com.myapp',
    deviceLockScreenInfo: 'No lock screen message',
    identity: 'Identity',
    clientCertRef: '6PQIEQYJKoZbdDu8gwggRlqCCAPEbAAcGClgvcNAQc',
    X509: 'TWFuIGlzIGRpc3Rpbmd1a',
    host: '192.168.20.123',
    port: '1337',
    pacUri: 'mysite.org/script',
    excludedHosts: '*.mysite.com',
    vpnPackageName: 'com.vpn.app',
    google: 'Google',
    selectEvents: 'Select events',
    resellerFromEmail: 'EMM no-reply'
};
