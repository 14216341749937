export default {
    passwordLength: 'Code must be 6 digits or 44 characters for backup codes',
    passwordsShouldBeEqual: 'Passwords should be equal',
    wholeNumber: 'Input must be a positive integer',
    noFreeEmail: 'Only business email address can be used',
    phoneNumber:
        'Only numbers and the following characters are allowed: + - ( )',
    onlyLowercase: 'Letters can only be lowercase',
    onlyAlphaNumericDash:
        'Only lowercase alpha-numeric characters and hyphen are allowed',
    packageName: 'Only lowercase alpha-numeric characters and dot are alowed',
    GeneralPolicyComponentForm: {
        invalidSystemUpdate:
            'Times should have at least 30 minutes of difference'
    },
    qrCodeLength: 'Code must have 6 characters',
    alphanumericMust: 'There must be at least one alpha-numeric character',
    fileTypeCSV: 'File must be of type csv',
    fileTypeImage: 'File must be of type image e.g. jpeg, png',
    boundEmailHelp:
        'Feel free to take note of the binding email for future reference'
};
