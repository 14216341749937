
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueFormulateContext } from '@/util/vueformulate-context';

@Component({})
export default class ReversedCheckbox extends Vue {
    @Prop() context!: VueFormulateContext;

    get value() {
        return !this.context.model;
    }

    set value(input: boolean) {
        this.context.model = !input;
    }

    get id() {
        return this.context.id;
    }
}
