import DenyPermission from './DenyPermission.en';
import PlanChangeSelect from './PlanChangeSelect.en';
import DeviceMemoryChart from './DeviceMemoryChart.en';
import DoughnutChart from './DoughnutChart.en';
import DeviceDetails from './DeviceDetails.en';
import DeviceRemoteControl from './DeviceRemoteControl.en';
import DeviceVariablesManager from './DeviceVariablesManager.en';
import FieldGroup from './FieldGroup.en';
import Footer from './Footer.en';
import LocaleLanguagesSelect from './LocaleLanguagesSelect.en';
import AdvancedPolicyComponentForm from './AdvancedPolicyComponentForm.en';
import ApplicationsPolicyComponentForm from './ApplicationsPolicyComponentForm.en';
import GeneralPolicyComponentForm from './GeneralPolicyComponentForm.en';
import KioskPolicyComponentForm from './KioskPolicyComponentForm.en';
import NetworkPolicyComponentForm from './NetworkPolicyComponentForm.en';
import SecurityPolicyComponentForm from './SecurityPolicyComponentForm.en';
import PolicyForm from './PolicyForm.en';
import DeviceLocation from './DeviceLocation.en';
import RolesSelect from './RolesSelect.en';
import PermissionsSelect from './PermissionsSelect.en';
import ReadOnlyAlert from './ReadOnlyAlert.en';
import ReportTypeSelect from './ReportTypeSelect.en';
import ZeroTouch from './ZeroTouch.en';
import ImpersonateAlert from './ImpersonateAlert.en';
import DataTableFilter from './DataTableFilter.en';
import DataTableSort from './DataTableSort.en';
import CustomTable from './CustomTable.en';
import FeedbackPrompt from './FeedbackPrompt.en';
import TrialAlert from './TrialAlert.en';
import FullscreenPostTrialAlert from './FullscreenPostTrialAlert.en';
import TeamsTable from './TeamsTable.en';
import BillingAlert from './BillingAlert.en';
import BrokenSubscriptionAlert from './BrokenSubscriptionsAlert.en';
import BindingAlertEn from './BindingAlert.en';
import BillingInfosTable from './BillingInfosTable.en';
import TeamColumns from './TeamColumns.en';
import UsersTable from './UsersTable.en';
import NetworkConfig from './NetworkConfig.en';

export default {
    DenyPermission,
    PlanChangeSelect,
    DeviceMemoryChart,
    DoughnutChart,
    DeviceDetails,
    DeviceRemoteControl,
    DeviceVariablesManager,
    FieldGroup,
    Footer,
    LocaleLanguagesSelect,
    AdvancedPolicyComponentForm,
    ApplicationsPolicyComponentForm,
    GeneralPolicyComponentForm,
    KioskPolicyComponentForm,
    NetworkPolicyComponentForm,
    SecurityPolicyComponentForm,
    PolicyForm,
    DeviceLocation,
    RolesSelect,
    PermissionsSelect,
    ReadOnlyAlert,
    ReportTypeSelect,
    ZeroTouch,
    ImpersonateAlert,
    DataTableFilter,
    DataTableSort,
    CustomTable,
    FeedbackPrompt,
    TrialAlert,
    FullscreenPostTrialAlert,
    TeamsTable,
    BillingAlert,
    BrokenSubscriptionAlert,
    BindingAlertEn,
    BillingInfosTable,
    TeamColumns,
    UsersTable,
    NetworkConfig
};
