import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Ripple from 'vuetify/lib/directives/ripple';
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify, {
    directives: {
        Ripple
    }
});

export default new Vuetify({
    iconfont: 'mdi',
    breakpoint: {
        thresholds: {
            sm: 414,
            md: 600,
            lg: 900
        },
        scrollBarWidth: 24
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#D84315',
                success: colors.green.darken1,
                successLight: colors.green.lighten5,
                error: colors.red.darken3,
                errorLight: colors.red.lighten5,
                warning: colors.yellow.darken3,
                warningLight: colors.yellow.lighten5,
                focusGreen: '#41b883'
            }
        }
    }
});
