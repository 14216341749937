
import { VueFormulateContext } from '@/util/vueformulate-context';
import { Vue, Component, Prop } from 'vue-property-decorator';

import { isAfter, format } from 'date-fns';
import { displayDateTime, displayMonthDay } from '@/util/functions/date-time';

type VueFormulateRule = {
    ruleName: string;
    args: string[];
};

@Component({})
export default class FormulateInputDate extends Vue {
    @Prop({ required: true }) context!: VueFormulateContext;
    /** Allow selecting two dates to form a range */
    @Prop({ default: false, type: Boolean }) range!: boolean;
    /** Directly show caledar, no need for dropdown */
    @Prop({ default: false, type: Boolean }) noDropdown!: boolean;
    /** Remove visual selection of year, only allow month and day */
    @Prop({ default: false, type: Boolean }) noYear!: boolean;
    /** Custom functions with allowed dates */
    @Prop({}) allowedDates!: () => boolean;

    displayDateTime = displayDateTime;

    menuModel = false;

    /** Get only date-related validation rules */
    get dateValidations() {
        // List of date-related validations
        const dateValidations = ['after'];

        // Filter out validation rules that don't relate to date validations
        return this.context.rules
            ?.filter((rule: VueFormulateRule) =>
                dateValidations.some((dateValidation) =>
                    rule.ruleName.includes(dateValidation)
                )
            )
            .map((rule: VueFormulateRule) => ({
                name: rule.ruleName,
                values: rule.args
            }));
    }

    /** Handle different date display according to display method */
    handleDateDisplay() {
        if (this.range) {
            // Date input that ignores year
            if (this.noYear) {
                // Only display day an month, no need for year
                if (this.context.model[0]) {
                    // If two dates are selected, display both
                    if (this.context.model[1]) {
                        return `${displayMonthDay(
                            this.context.model[0]
                        )} ${displayMonthDay(this.context.model[1])}`;
                    } else {
                        // Only one date is selected, display only that one
                        return displayMonthDay(this.context.model[0]);
                    }
                }
            } else {
                // Display day, month, year
                return `${displayDateTime(this.context.model[0], {
                    showTime: false
                })} ${displayDateTime(this.context.model[1], {
                    showTime: false
                })}`;
            }
        } else {
            return this.noYear
                ? // Only display day and month
                  displayMonthDay(this.context.model)
                : displayDateTime(this.context.model, { showTime: false });
        }
    }

    handleDateInput() {
        if (!this.noDropdown && !this.range) {
            // Close dropdown
            this.menuModel = false;
        }
    }

    /** Handle date validations to get allowed dates */
    appliedAllowedDates(testingDate: string) {
        return this.dateValidations?.every((validation) => {
            if (validation.name === 'after') {
                return isAfter(
                    new Date(testingDate),
                    new Date(validation.values[0])
                );
            } else {
                return false;
            }
        });
    }

    getMonthName() {
        return (date: string) => `${format(new Date(date), 'MMMMMM')}`;
    }
}
