export default {
    profile: 'Profile',
    teams: 'Teams',
    passwordAndTwoFa: 'Passwords & 2FA',
    sessions: 'Sessions',
    adminDashboard: 'Admin dashboard',
    payments: 'Payments',
    resellers: 'Resellers',
    users: 'Users',
    serverLogs: 'Server logs',
    management: 'Management',
    policies: 'Policies',
    policyComponents: 'Policy Components',
    enrollmentTokens: 'Enrollment',
    devices: 'Devices',
    settings: 'Settings',
    organization: 'Organization',
    members: 'Members',
    roles: 'Roles',
    compliance: 'Compliance',
    reports: 'Reports',
    billing: 'Billing',
    developers: 'Developers',
    webhooks: 'Webhooks',
    accessTokens: 'Access Tokens',
    support: 'Support',
    team: 'Team',
    customerDetails: 'Customer details',
    subscription: 'Subscription',
    paymentMethods: 'Payment methods',
    invoices: 'Invoices',
    creditsAndHistory: 'Credits & history',
    logs: 'Logs',
    delete: 'Delete',
    offlineCompliance: 'Offline Compliance'
};
