import { SHA1 as SHA1Polyfill, enc } from 'crypto-js';

// Determine if native or polyfill should be used
const useNative = !!crypto.subtle;

/**
 * Transform an array buffer into a string
 * @param buffer Array buffer to parse
 */
export const bufferToString = (buffer: ArrayBuffer) => {
    // Parse uint array
    const data = Array.from(new Uint8Array(buffer));

    // Return buffer as string
    return data.map((b) => b.toString(16).padStart(2, '0')).join('');
};

/**
 * Transform a string input into a SHA1 hash string
 * @param data SHA1 string input
 */
export const SHA1 = (data: string): Promise<string> =>
    useNative
        ? crypto.subtle
              .digest(
                  // Hash with SHA-1
                  'SHA-1',
                  // Parse data into uint array
                  new TextEncoder().encode(data)
              )
              .then(bufferToString)
        : // Fallback to hash with SHA-1 using npm module
          Promise.resolve(SHA1Polyfill(data).toString(enc.Hex));
