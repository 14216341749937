import { AxiosResponse } from 'axios';
import store from '@/store';
import Axios from './axios';
import { SHA1 } from '../util/hash';

// Add interceptor to set signal for cancelling requests
Axios.interceptors.request.use(async (config) => {
    // Skip signaling if not a GET request
    if (config?.method?.toLowerCase() !== 'get') {
        return config;
    }

    // Exclude special routes called multiple times throughout stores and components
    if (config?.url?.startsWith('/users/me')) {
        return config;
    }

    // Create new controller
    const controller = new AbortController();

    // Generate route hash
    const { baseURL, url, params } = config;
    const hash = await SHA1(`${baseURL}${url}${JSON.stringify(params)}`);

    // Set this request signal
    config.signal = controller.signal;

    // Set route hash and signal
    store.dispatch('addActiveRequest', {
        hash,
        payload: {
            controller,
            baseURL,
            url,
            params
        }
    });

    // Return config
    return config;
});

// Add interceptor to remove requests from signal map
// Factory function to include `Promise.reject` for error responses
const responseCallback =
    (error = false) =>
    async (c: AxiosResponse<any, any>) => {
        // Get request config from response
        const { config } = c;

        if (!config) {
            console.warn('No config given in response:', c);
            return error ? Promise.reject(c) : c;
        }

        // Skip signaling if not a GET request
        if (config?.method?.toLowerCase() !== 'get') {
            return error ? Promise.reject(c) : c;
        }

        // Generate route hash
        const { baseURL, url, params } = config;
        const hash = await SHA1(`${baseURL}${url}${JSON.stringify(params)}`);

        // Clear route hash and signal
        store.dispatch('removeActiveRequest', hash);

        return error ? Promise.reject(c) : c;
    };

Axios.interceptors.response.use(responseCallback(), responseCallback(true));
