export default {
    title: 'Resellers',
    addDialog: {
        title: 'Create reseller',
        text: 'Resellers will be able to register a domain for white label usage of Balloon.',
        button: 'Create reseller'
    },
    searchFields: 'name',
    noData: 'No resellers found',
    platformSelect: {
        mambo: 'Mambo',
        rhino: 'Rhino Configure'
    },
    domainValidation: {
        errorTopLevel:
            'Domain must have a top-level domain with at least two characters, like .com',
        errorSecondLevel: `Domain must have a second-level domain with at least two characters, like 'google'`
    }
};
