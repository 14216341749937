export default {
    title: 'Manually trigger scheduled jobs',
    createMissingCustomers: 'Create team customers',
    subscribeEnterprisePubsub: 'Subscribe enterprises to AMAPI PubSub',
    jobs: {
        syncDevices: 'Sync devices',
        setTeamDefaultCustomVariables:
            'Create default custom variables for teams',
        requeuePendingTasks: 'Requeue pending backend tasks',
        deleteExpiredSessions: 'Clear expired login sessions',
        deleteExpiredWebtokens: 'Clear expired AMAPI web tokens',
        deleteExpiredEnrollmentTokens: 'Clear expired device enrollment tokens',
        deleteExpiredPendingEmailChanges: 'Clear expired pending email changes',
        syncStripePlans: 'Sync Stripe plans',
        setAllPoliciesPending: 'Set all policies as deploy pending',
        checkTeamDeletion: 'Run team deletion cron',
        sendSalesReport: 'Send sales report',
        apply100FreeDeviceCoupon: 'Apply 100FreeDevices coupon',
        updateBillingInfo: 'Update billing info',
        pruneOldLocations: 'Prune old locations',
        notifyPausedNonPaymentFlowTeamsToReseller:
            'Trigger Paused non-payment flow Teams email'
    }
};
