export default {
    title: 'Devices',
    item: 'device | devices',
    variablesDialog: {
        title: 'Device variables'
    },
    text: 'You can add a device by using an',
    enterpriseChangedAlert: {
        text: 'We noticed you have previously deleted and rebound your AMAPI enterprise. Devices previously enrolled may not be currently enrolled under this new team and will require re-enrollment'
    },
    bulkChangeDialog: {
        title: 'Change device policy | Change devices policy',
        text: {
            p1: 'Are you sure you want to change the policy of',
            p2: 'device from | devices from',
            p3: '?'
        }
    },
    bulkDeleteDialog: {
        title: 'Delete device | Delete devices',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'device from | devices from',
            p3: '?'
        },
        notReversible:
            'This action is not reversible. It will delete the device record and issue a wipe command to the device. | This action is not reversible. It will delete them from the devices list and issue a wipe command to each device.'
    },
    bulkCommandDialog: {
        title: '{0} command',
        text: {
            p1: 'You are about to',
            p2: 'reset the password of',
            p3: '1 device. | {count} devices.',
            possessionStateChange: {
                p1: 'change the possession state of',
                p2: '1 device | {0} devices'
            }
        }
    },
    changeDialog: {
        title: 'Change policy of {0} ({1})',
        text: {
            p1: 'The device currently has the policy named',
            p2: 'applied.'
        }
    },
    viewDialog: {
        title: '{0} ({1}) device'
    },
    deleteDialog: {
        title: 'Delete device',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'device with a serial number of',
            p3: 'from',
            p4: '?'
        },
        notReversible:
            'This action is not reversible and it will wipe the entire device and delete it from your devices list.'
    },
    remotecontrolDialog: {
        title: 'Remote control'
    },
    commandDialog: {
        title: '',
        text: {
            p1: 'You are about to',
            p2: 'reset the password of',
            p3: '.'
        },
        model: 'Model:',
        serialNumber: 'Serial number:',
        expiryInfo:
            'The command expires in 10 minutes by default, but you can optionally customize it below.',
        setExpiry: 'Set custom expiry'
    },
    uploadDialog: {
        title: 'CSV upload',
        text: {
            p1: 'You can bulk map variables through a CSV file, we will send you an email once the import has been completed.',
            p2: 'The minimum required variables are:',
            p3: '$deviceManufacturer$, $deviceModel$, and $deviceSerialNumber$',
            p4: '. You can download a sample CSV below.'
        }
    },
    commandExpiryHelp: 'Leave empty for no expiry',
    resetPasswordOptions: {
        requireEntry: "Don't allow another reset until user enters password",
        doNotAskForCredentials: "Don't ask for password on device boot",
        lockNow: 'Lock device after password reset'
    },
    searchFields: 'model or serial number',
    deleting: 'Deleting',
    deletingInfo:
        "Google's services are in the process of removing the device, this might take a little while.",
    noData: 'You have not added any devices',
    handleCommandTitle: {
        password: "Reset device's password",
        default: '{0} device'
    },
    deviceStateSelect: {
        active: 'Active',
        disabled: 'Disabled',
        pendingDelete: 'Pending delete',
        provisioning: 'Provisioning',
        deleted: 'Deleted',
        unspecified: 'Unspecified'
    },
    multiMatchToDelete: 'Delete 1 device | Delete {count} devices',
    matchToDelete: 'Delete {0}',
    updatePossession: 'Update Possession',
    state: 'State',
    reason: 'Reason',
    currentPossessionState: '{0} is currently {1}',
    possessionWarning: 'Device has been {0}. Reason: {1}',
    broadcastMessageTo: 'Broadcast a message to',
    report: {
        info: 'This report will be generated with the applied search and filters you currently have on the table.',
        success: {
            p1: "The request has been sent, you can go to your team's",
            reportsPage: 'reports page',
            p2: "to download the report once it's been generated."
        },
        error: 'Unable to generate report, please try again'
    },
    columns: {
        policyCompliant: 'Policy compliant',
        androidVersion: 'Android version',
        apiLevel: 'API level',
        updateStatus: 'Update status',
        lastHeartbeat: 'Last heartbeat'
    },
    policyCompliant: {
        true: 'Compliant',
        false: 'Non-compliant'
    }
};
