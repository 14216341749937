
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueFormulateContext } from '@/util/vueformulate-context';

@Component({})
export default class BooleanSelect extends Vue {
    @Prop() context!: VueFormulateContext;
    @Prop() allowUndefined!: boolean;

    get value() {
        // Parse model as string
        switch (this.context.model) {
            case true:
                return 'true';

            case false:
            default:
                return 'false';

            // default:
            //     return '';
        }
    }

    set value(input: string) {
        switch (input) {
            // Set true boolean
            case 'true':
                this.context.model = true;
                break;

            // Set false boolean
            case 'false':
            default:
                this.context.model = false;
                break;

            // Set undefined literal
            // case 'undefined':
            // default:
            //     this.context.model = undefined;
            //     break;
        }
    }
}
