import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';

export default (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
    const route = to;
    const redirect = next;
    const { query } = route;

    // If authenticated go to query route or dashboard
    if (store.getters['auth/isAuthenticated']) {
        if (typeof query.to === 'string') {
            return redirect(query.to);
        }
        return redirect('/');
    }
    next();
};
