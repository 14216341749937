import Vue from 'vue';
import axios from 'axios';
import { Platform } from '@balloon/types';

// Create Axios instance
const Axios = axios.create({
    baseURL: `https://dashboard.balloonemm.me/api/platform/${Platform.MAMBO}/v1`
});

// Export Axios instance
export default Axios;

// Add Axios instance to Vue prototype
Vue.prototype.$axios = Axios;
