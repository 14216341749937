import { IMemberships, IRoles, WebSocketEvent } from '@balloon/types';
import Vue from 'vue';
import { Subject } from 'rxjs';

// Add permissions subject for broadcasting permission changes
export const permissions$ = new Subject<
    WebSocketEvent<IRoles | IMemberships>
>();

// Add permissions subject to Vue protototype
Vue.prototype.$permissions$ = permissions$;
