import psl, { ParsedDomain } from 'psl';
import store from '@/store';
import { User } from '@/store/user';
import { NavigationGuardNext, Route } from 'vue-router';
import { IMemberships, ITeams, IUsers } from '@balloon/types';

export default (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
    const redirect = next;
    const route = to;

    // Parse current URL
    const URL = psl.parse(window.location.hostname) as psl.ParsedDomain;
    if (URL.error) {
        throw new Error('Invalid URL');
    }

    // Maintenance page
    if (route.path === '/maintenance') {
        return next();
    }

    // Get required flow data
    const isAuthenticated: boolean = store.getters['auth/isAuthenticated'];
    const currentTeam: ITeams = store.getters['team/team'];
    const isReseller = !!currentTeam?.ofResellerId;
    const { id: currentTeamId = null } = currentTeam;
    const isMember: boolean = store.getters['team/isMember'];
    const user: Partial<IUsers> = store.getters['user/details'];
    const userStore: User = store.getters['user/user'];
    const { isPendingDelete } = currentTeam;
    const isOwner = store.getters['user/isOwner'];
    const { onboardingCompleted: userOnboardingCompleted = false } = user;
    const currentMembership = userStore?.memberships?.find(
        (membership) => membership.teamId === currentTeamId
    );
    const currentResellerMemberships: IMemberships[] =
        store.getters['user/currentResellerMemberships'];

    // Determine the team to which we will redirect the user
    // By default it is the last joined team
    let firstTeamAvailable = currentResellerMemberships?.length
        ? currentResellerMemberships?.[0].team
        : null;

    // Check if the user has any preference, and it applies to this reseller
    const preferredMembershipId = userStore?.details?.preferredMembershipId;
    if (
        preferredMembershipId &&
        Array.isArray(userStore?.memberships) &&
        currentResellerMemberships.some((m) => m.id === preferredMembershipId)
    ) {
        const preferredMembership = store.getters['user/preferredMembership'];

        // Use the preferred team
        if (preferredMembership) {
            firstTeamAvailable = preferredMembership?.team;
        }
    }

    // Handle team specific redirection if not on `dashboard`
    if (URL.subdomain !== 'dashboard') {
        // If team doesn't exist display a 'register this team' page
        if (
            !currentTeamId &&
            route.path !== '/new' &&
            !route.path.startsWith('/users') &&
            !route.path.startsWith('/auth') &&
            !route.path.startsWith('/admin')
        ) {
            return redirect('/new');
        }

        // If not authenticated ignore, authentication middleware will handle it
        if (
            !isAuthenticated ||
            route.path === '/401' ||
            route.path === '/new' ||
            route.path.startsWith('/admin') ||
            route.path.startsWith('/users')
        ) {
            return next();
        }

        // If not a member, return 401 access denied
        if (!isMember && route.path !== '/401') {
            return redirect('/401');
        }
    }

    // If not authenticated ignore, authentication middleware will handle it
    if (!isAuthenticated) {
        return next();
    }

    // If team is pending delete, then redirect non-owners to dashboard
    if (isPendingDelete && !isOwner && route.path !== '/') {
        return redirect('/');
    }

    // If onboarding required, redirect there
    if (
        (!userOnboardingCompleted ||
            (currentMembership && !currentMembership?.onboardingComplete) ||
            (isReseller && !currentTeam?.logo)) &&
        route.path !== '/onboarding/user'
    ) {
        return redirect('/onboarding/user');
    }

    // If 2FA is enforced, redirect there
    if (
        !route.path.startsWith('/onboarding') &&
        !route.path.startsWith('/auth') &&
        !route.path.startsWith('/user') &&
        !route.path.startsWith('/setup-2fa') &&
        !route.path.startsWith('/admin') &&
        currentTeam?.forceTwoFactor &&
        !user.twoFactorEnabled
    ) {
        return redirect('/setup-2fa');
    }

    // If user is already on a valid page don't try to redirect
    if (
        (URL as ParsedDomain)?.subdomain === 'dashboard' &&
        !route.path.startsWith('/onboarding') &&
        !route.path.startsWith('/auth') &&
        !route.path.startsWith('/user')
    ) {
        console.log('Redirecting to team', firstTeamAvailable);

        // Redirect
        if (firstTeamAvailable) {
            // Check if there's any path we should redirect to
            const queries = new URLSearchParams(window.location.search);
            const path = queries.get('to') ?? '';

            redirect(false);

            // Perform redirection
            window.location.href = `${window.location.protocol}//${
                firstTeamAvailable?.username
            }.${(URL as ParsedDomain).domain}${
                window.location.port ? `:${window.location.port}` : ``
            }${path}`;
            return;
        }
    }
    next();
};
