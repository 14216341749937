export default {
    withDate: {
        p1: 'This is the billing history since {date} of',
        p2: ' - Stripe customer ID:'
    },
    withoutDate: {
        p1: 'This is the billing history of',
        p2: ' - Stripe customer ID:'
    }
};
