import store from '@/store/index';

/** Options allowed for Intl.DateTimeFormat */
type DateTimeOptions = Intl.DateTimeFormatOptions;

/** Receives a date like 'yyyy-mm-dd'
 * and returns it as a readable string
 * according to the user's language preference
 */
function formatDate(dateString: string) {
    const [year, month, day] = dateString.split('-');

    const date = new Date(+year, +month - 1, +day);
    return new Intl.DateTimeFormat(
        store.getters['user/user'].details.prefersLanguage,
        {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        }
    ).format(date);
}

/**
 * Receives a date and returns it as a readable string
 * according to user's preferences and timezone.
 * @param { string | number | Date } date - Date to format, should come as ISO string | timestamp | Date
 * Options: { showTime?: boolean = true, showDate?: boolean = true }
 * @param { Options } [Options] - Time / Date can be included / excluded from the final string

 * DateTimeOptions: Intl.DateTimeFormatOptions
 * @param { DateTimeOptions } [DateTimeOptions] - Receive a whole Intl.FormatDateTimeOptions object to use on the format
 * @return { string } Readable date as string
 */
function displayDateTime(
    date: string | number | Date,
    {
        showTime = true,
        showDate = true
    }: {
        showTime?: boolean;
        showDate?: boolean;
    } = {},
    customDateTimeOptions: DateTimeOptions = {}
) {
    const isDateObject =
        Object.prototype.toString.call(date) === '[object Date]';

    // Check for type of date received and handle accordingly
    const finalDate = isDateObject
        ? (date as Date)
        : new Date(isNaN(+date) ? date : +date);

    const options: DateTimeOptions = {};

    if (showDate) {
        options.day = '2-digit';
        options.month = 'short';
        options.year = '2-digit';
    }

    if (showTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
    }

    // If given a whole DateTimeOptions for Intl, use in conjunction with `options`
    Object.assign(options, customDateTimeOptions);

    // Use main options only
    return new Intl.DateTimeFormat(
        store.getters['user/user'].details.prefersLanguage,
        options
    ).format(finalDate);
}

/**
 * Receives a date as string and returns it as a readable string
 * according to user's preferences.
 * The return excludes the year and only gives month and day
 */
function displayMonthDay(dateString: string) {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat(
        store.getters['user/user'].details.prefersLanguage,
        {
            day: '2-digit',
            month: 'short'
        }
    ).format(date);
}

export { formatDate, displayDateTime, displayMonthDay };
