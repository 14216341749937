export default {
    error: "There was an error getting the device's location, please try again.",
    locateEvery: 'You can locate a device every 5 minutes',
    locating: 'Locating device',
    locateAgain: 'You can locate again in',
    showingLocationAt: 'Showing location at',
    notLocatedBefore: 'You have not located this device before.',
    locationAt: 'Location at',
    noCompanion: 'Android device not ready to be located yet, please try again in a few minutes.'
};
