export default {
    subtitle1: 'Get started quickly',
    text1: 'Enroll and manage your devices with ease from a simple to use dashboard or integrate with your existing systems.',
    subtitle2: 'Support your growing Android fleet',
    text2: 'Make sure you have the right information at the right time, with tools at your disposal to sort out any issue in no time.',
    subtitle3: 'Join countless businesses',
    text3: 'Trusted by ambitious startups and enterprises of every size.',
    title: 'Sign up',
    alreadyHaveAccount: 'Already have an account?',
    didntReceiveEmail: "Didn't receive the email?",
    checkYourSpam: 'Check your spam folder or resend in {0} seconds.',
    emailSent: 'We have sent you another email.',
    typePassword: 'Or set a password'
};
