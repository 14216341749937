export default {
    forgotPassword: 'Forgot your password?',
    signIn: 'Sign in',
    signUp: 'Sign up',
    enrollmentToken: 'Enrollment Token',
    policyComponents: 'Policy Components',
    billing: 'Billing',
    billingPage: 'Billing page',
    termsOfService: 'Terms of Service',
    managedGooglePlayAgreement: 'Managed Google Play Agreement',
    enrollDevices: 'Enroll Devices',
    teamsPage: 'Teams Page',
    settings: 'Settings',
    policy: 'Policy',
    membersPage: 'Members page',
    compatibleBrowser: 'compatible browser',
    register: 'Register',
    androidApiLevels: 'Android API Levels',
    deletePage: 'Team Settings',
    supportPage: 'Support page'
};
