export default {
    configure: 'Configure',
    confirmChange: 'Confirm change',
    requestReport: 'Request report',
    download: 'Download',
    delete: 'Delete',
    yesDelete: 'Yes, delete',
    cancelDelete: 'Cancel deletion',
    view: 'View',
    add: 'Add',
    addItem: 'Add {0}',
    createItem: 'Create {0}',
    duplicate: 'Duplicate',
    update: 'Update',
    viewVariables: 'View variables',
    manageVariables: 'Manage variables',
    viewDetails: 'View details',
    remoteControl: 'Remote control',
    changePolicy: 'Change policy',
    yesChange: 'Yes, change',
    yesItem: 'Yes, {0}',
    change: 'Change',
    upload: 'Upload',
    endSession: 'End session',
    uploadVariablesData: 'Upload variables data',
    showDetails: 'Show details',
    createToken: {
        multipleOptions: 'Through token',
        singleOption: 'Create token'
    },
    zeroTouch: 'Through zero-touch',
    components: 'Components',
    edit: 'Edit',
    remove: 'Remove',
    soleTeamOwner: 'Sole team owner',
    yesRemove: 'Yes, remove',
    roleInUse: 'Role in use',
    invite: 'Invite',
    enable2Fa: 'Enable 2FA',
    disable2Fa: 'Disable 2FA',
    yesRegenerate: 'Yes, regenerate',
    logout: 'Logout',
    yesLogout: 'Yes, logout',
    goToTeam: 'Go to team',
    leave: 'Leave',
    yesLeaveTeam: 'Yes, leave team',
    close: 'Close',
    removeItem: 'Remove {0}',
    addApp: 'Add app',
    changePossession: 'Change Possession State',
    bindEnterprise: 'Bind Enterprise',
    importPolicies: 'Import policies',
    updateDevicePolicies: 'Update device policies',
    sendMessage: 'Send message',
    send: 'Send',
    applyFilter: 'Apply filter',
    clearData: 'Clear data',
    removeAll: 'Remove all',
    resetPassword: 'Reset Password',
    impersonate: 'Impersonate',
    removeFromTeam: 'Remove from team',
    save: 'Save',
    bulkUpload: 'Bulk Upload',
    hide: 'Hide',
    viewBillingHistory: 'View billing history',
    changeReadOnlyState: 'Change read-only state',
    changeState: 'Change state',
    saveChange: 'Save change',
    pauseFlow: 'Pause flow',
    restartFlow: 'Restart flow',
    viewOwners: 'View owners',
    fixBrokenPlan: 'Fix plan'
};
