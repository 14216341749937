import Vue from 'vue';

export interface IEnv {
    NODE_ENV?: string;
    SUPERADMINS: string[];
    GOOGLE_API_KEY?: string;
    TEAM_DELETE_PERIOD: number;
}

// Create env instance
const env: IEnv = {
    NODE_ENV: process.env?.NODE_ENV,
    SUPERADMINS: JSON.parse(process.env?.VUE_APP_SUPERADMINS ?? '[]'),
    GOOGLE_API_KEY: process.env?.VUE_APP_GOOGLE_API,
    TEAM_DELETE_PERIOD: Number(process.env?.VUE_APP_TEAM_DELETE_PERIOD || 30)
};

// Export env
export default env;

// Add env to Vue prototype
Vue.prototype.$env = env;
