
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueFormulateContext } from '@/util/vueformulate-context';

@Component({})
export default class NumericInput extends Vue {
    @Prop() context!: VueFormulateContext;

    get value() {
        return this.context.model;
    }

    set value(input: number) {
        this.context.model = input;
    }
}
