export default {
    title: 'Offline Compliance',
    explainer:
        "You may define an offline compliance policy to be applied to a device automatically after it's been offline for a set period.",
    success: 'Offline compliance has been updated.',
    changeWarning:
        'Changing the offline compliance configuration will cause all policies to redeploy to send the new config to devices.',
    previewPolicy: 'Preview policy',
    previewNamePolicy: 'Preview {policyName} policy',
    errorPreviewPolicy:
        "There's been an error while fetching this policy, please try again."
};
