export default {
    permissionGrants: 'Override individual permission requests',
    permissionGrantsItem: 'override | overrides',
    appsList: 'Application-specific policies',
    addApplication: 'Add application',
    playStoreDown:
        'Google Play Store servers seem to be down, try later to add more applications.',
    thisAppHasNoManagedConfiguration: 'This app has no managed configuration.',
    itemPermissionGrants: 'Grant permissions',
    itemPermissionGrantsItem: 'permission | permissions',
    noData: 'You have not added any apps',
    personalAppsList: 'Applied to applications on the personal profile',
    packageInUse: 'This package is already part of the list',
    managedConfigDialog: {
        title: 'Managed configuration'
    },
    delegatedScopesAlert: {
        p1: 'Network activity and security logs are restricted delegated scopes in use by our system, setting them may cause policy deployment to fail.',
        p2: 'Please contact us via our',
        // support page
        p3: 'if you want to use any of these.'
    },
    label: {
        playStoreMode: 'Default policy for Google Play Store',
        appAutoUpdatePolicy: 'Default policy for app auto updates',
        defaultPermissionPolicy: 'How to handle app permission requests',
        personalUsagePolicies: 'Personal usage policies',
        personalUsagePoliciesPersonalPlayStoreMode:
            'Control how apps in the personal profile are handled',
        alwaysOnVpnLockdownExemption: {
            label: 'Exempt from always-on VPN connection',
            help: 'If exempted, the app will be allowed to use regular networking even if the VPN is unavailable'
        },
        workProfileWidgets: 'Home screen widgets permissions'
    },
    select: {
        playStoreMode: {
            unspecified: 'Default',
            whitelist: 'Only whitelisted apps',
            blacklist: 'Block blacklisted apps'
        },
        appAutoUpdatePolicy: {
            unspecified: 'Default',
            always: 'Always',
            never: 'Never',
            choiceToTheUser: 'Choice to the user',
            wifiOnly: 'Wi-Fi only'
        },
        personalPlayStoreMode: {
            unspecified: 'Default',
            allowlist: 'Only whitelisted apps',
            blocklist: 'Block blacklisted apps'
        },
        defaultPermissionPolicy: {
            unspecified: 'Default',
            prompt: 'Prompt device user',
            grant: 'Always grant permissions',
            deny: 'Always deny permissions'
        },
        installType: {
            unspecified: 'Default',
            preinstalled: 'Pre-installed',
            forceInstalled: 'Installed',
            blocked: 'Blocked',
            available: 'Available',
            requiredForSetup: 'Required for setup',
            kiosk: 'Kiosk'
        },
        delegatedScopes: {
            unspecified: 'Default',
            certInstall: 'Cert install',
            managedConfigurations: 'Managed configurations',
            blockUninstall: 'Block uninstall',
            permissionGrant: 'Permission grant',
            packageAccess: 'Package access',
            enableSystemApp: 'Enable system app',
            networkActivityLogs: 'Enable network activity logs',
            securityLogs: 'Enable security logs'
        },
        connectedWorkAndPersonalApp: {
            unspecified: 'Default',
            disallowed: 'Disallowed',
            allowed: 'Allowed'
        },
        permissionPolicy: {
            unspecified: 'Default',
            prompt: 'Prompt',
            grant: 'Grant',
            deny: 'Deny'
        },
        autoUpdateMode: {
            unspecified: 'Default',
            postponed: 'Postponed',
            highPriority: 'High Priority'
        },
        personalInstallType: {
            unspecified: 'Default',
            blocked: 'Blocked',
            available: 'Available'
        },
        alwaysOnVpnLockdownExemption: {
            unspecified: 'Default',
            enforced: 'Enforce VPN',
            exemption: 'Exempt from VPN'
        },
        workProfileWidgets: {
            unspecified: 'Default',
            allowed: 'Allowed',
            disallowed: 'Disallowed'
        }
    }
};
