export default {
    deviceCommands: {
        lock: 'Lock',
        resetPassword: 'Reset password',
        reboot: 'Reboot',
        broadcastMessage: 'Broadcast message',
        clearData: 'Clear data'
    },
    UpdateStatus: {
        unknown: 'Unknown',
        upToDate: 'Up to date',
        unknownUpdateAvailable: 'Software update is available',
        securityUpdateAvailable: 'Security update is available',
        osUpdateAvailable: 'OS update is available'
    },
    
}