export default {
    intro: 'We hope you have been enjoying the trial period!',
    billing: {
        p1: 'It will end in',
        p2: 'and has been leveraging features in the ',
        p3: 'plan.',
        p4: 'Make sure to set a payment in your',
        p5: "before the trial ends to continue uninterrupted, or if you'd prefer not to continue you may delete this team via ",
        p6: 'this page'
    },
    admin: {
        p1: 'It will end in',
        p2: ', so make sure to set up a payment in your'
    },
    notAdmin: {
        p1: 'It will end in',
        p2: ', so please reach out to a team administrator so they can set up a payment before trial ends to continue uninterrupted. Otherwise, the team will be set to read-only mode.'
    },
    lessThanAnHour: 'less than an hour'
};
