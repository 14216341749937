export default {
    title: 'We are here to help',
    text: {
        p1: 'Need support? Please send an email to',
        p2: "and we'll help you shortly."
    },
    resellerLogo: '{0} logo',
    supportPortal: {
        p1: 'Complete {0} getting started guides, FAQs, and user documentation can be found in the',
        here: 'support portal',
        p2: '.'
    }
};
