export default {
    group: {
        team: 'Team',
        enterprise: 'Enterprise',
        resellers: 'Resellers',
        roles: 'Roles',
        memberships: 'Memberships',
        policies: 'Policies',
        policyComponents: 'Policy components',
        enrollmentTokens: 'Enrollment tokens',
        devices: 'Devices',
        accessTokens: 'Access tokens'
    },
    text: {
        teamUpdate: 'Update of team',
        enterpriseCreate: 'Creation of AMAPI enterprise',
        enterpriseDelete: 'Deletion of AMAPI enterprise',
        resellerUpdate: 'Update of reseller',
        roleCreate: 'Creation of role',
        roleUpdate: 'Update of role',
        roleDelete: 'Deletion of role',
        membershipCreate: 'Creation of membership',
        membershipUpdate: 'Update of membership',
        membershipDelete: 'Deletion of membership',
        policyCreate: 'Creation of policy',
        policyUpdate: 'Update of policy',
        policyDelete: 'Deletion of policy',
        policyComponentCreate: 'Creation of policy component',
        policyComponentUpdate: 'Update of policy component',
        policyComponentDelete: 'Deletion of policy component',
        enrollmentTokenCreate: 'Creation of enrollment token',
        enrollmentTokenUpdate: 'Update of enrollment token',
        enrollmentTokenDelete: 'Deletion of enrollment token',
        deviceEnroll: 'Enrollment of device',
        deviceUpdate: 'Update of device',
        deviceDelete: 'Deletion of device',
        accessTokenCreate: 'Creation of access token',
        accessTokenUpdate: 'Update of access token',
        accessTokenDelete: 'Deletion of access token'
    }
};
