export default {
    title: 'Email verified',
    text: 'Your email was successfully verified. You can start using your account.',
    redirecting: 'We are redirecting you to your dashboard',
    goToLogin: 'Go to login',
    wipePasswordOk:
        'Your password has been removed but you are still able to log via passwordless login.',
    disable2faOk:
        'Two-factor authentication has been disabled for your account.',
    verifyEmail: {
        title: 'Verify email',
        text: 'Please click the button below to verify your email and continue the signup process.',
        action: 'Verify'
    }
};
