export default {
    title: 'Data and security',
    subtitle1: 'Download your data',
    text1: "You have the right to export all your data. You can download it in JSON format to transfer it to other tools. This does not include an export of your teams' data.",
    error_download: "We couldn't download your data, please try again.",
    subtitle2: 'Delete your account',
    text2: "You can delete your account and all its data permanently. If any of your teams has multiple users, it won't be deleted, and any paid subscriptions will remain.",
    dialog: {
        title: 'Deleting account',
        text: 'Are you sure you want to delete your account? This action is not reversible.'
    },
    error_delete: "We couldn't delete your account, please try again.",
    matchToDelete: 'I want to delete my account'
};
