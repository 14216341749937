import localforage from 'localforage';

localforage.config({
    name: 'EMM',
    version: 1.0,
    storeName: 'localData',
    description: 'EMM local storage'
});

export default {
    get<T = any>(key: string) {
        return localforage.getItem<T>(key);
    },
    set<T = any>(key: string, value: any) {
        return localforage.setItem<T>(key, value);
    },
    delete(key: string) {
        return localforage.removeItem(key);
    },
    clear() {
        return localforage.clear();
    },
    keys() {
        return localforage.keys();
    },
    keyCount() {
        return localforage.length();
    }
};
