export default {
    itemComponent: '{0} component',
    itemsComponent: '1 component | {count} components',
    isCurrentlySetTo: 'is currently set to',
    noMatch: 'No matching components',
    policyChange: {
        p1: 'Please confirm change to apply',
        p2: 'into',
        p3: 'policy.'
    },
    componentHeading: '{0} {1} component preview',
    componentsCanBeEdited: 'Components can be edited on',
    containsUpdate: '(Contains update)'
};
