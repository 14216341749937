export default {
    basicType: {
        title: 'Basic assets report',
        text: 'contains an overview of devices and their principal properties',
        info: '(Manufacturer, model, serial number, IMEI, MEID, policy name).'
    },
    detailedType: {
        title: 'Detailed assets report',
        text: 'includes complete information for each device',
        info: '(Postures, software, hardware, applications, operations, etc).'
    },
    teamBillingType: {
        title: 'Teams billing report',
        text: 'includes complete information for teams including their up-to-date billing info',
        info: '(ID, device count, subscription, invoices, etc).'
    },
    pdf: 'The report is generated in PDF format.',
    csv: 'The report is generated in CSV format.'
};
