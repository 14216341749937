import {
    IMemberships,
    ITeams,
    IPlans,
    PlanFeatureFlags,
    Permissions,
    ITeamBillingInfos,
    StripeSubscriptionState,
    ReadOnlyReason,
    NonPaymentFlowState
} from '@balloon/types';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import psl, { ParsedDomain } from 'psl';
import { differenceInDays, isFuture } from 'date-fns';
import Vue from 'vue';

import Axios from '@/plugins/axios';
import Vuetify from '@/plugins/vuetify';

import { PaginatedData } from '@/util/page-data.type';
import { BillingIssue, BillingIssueType } from '@/util/type/billing-issue.type';

export const namespaced = true;

export type TeamState = {
    team: Partial<ITeams>;
    slug: string;
    isMember: boolean;
    logoObjectURL: string | null;
};

export const state = (): TeamState => ({
    team: {},
    slug: '',
    isMember: false,
    logoObjectURL: null
});

export const mutations: MutationTree<TeamState> = {
    setTeam(state: TeamState, team: ITeams) {
        Vue.set(state, 'team', team);
    },
    updateTeam(state: TeamState, team: ITeams) {
        if (team.id === state.team.id) {
            Vue.set(state, 'team', team);
        }
    },
    setSlug(state: TeamState, slug: string) {
        Vue.set(state, 'slug', slug);
    },
    setIsMember(state: TeamState, isMember: boolean) {
        Vue.set(state, 'isMember', isMember);
    },
    setLogoObjectURL(state: TeamState, url: string) {
        if (state.logoObjectURL) {
            URL.revokeObjectURL(state.logoObjectURL);
        }
        state.logoObjectURL = url;
    },
    setBillingInfo(state: TeamState, billingInfo: ITeamBillingInfos) {
        console.log("Update team's current billing info:", billingInfo);

        Vue.set(state, 'team', {
            ...state.team,
            currentBillingInfo: billingInfo
        });
    }
};

export const actions: ActionTree<TeamState, any> = {
    async setTeam({ commit, rootState, dispatch, rootGetters, getters }) {
        // Parse URL
        const URL = psl.parse(window.location.hostname);

        // No team is selected
        if (
            URL.error ||
            (URL as ParsedDomain)?.subdomain === 'dashboard' ||
            !(URL as ParsedDomain)?.subdomain
        ) {
            // Fetch latest memberships
            await dispatch('user/updateUser', null, { root: true });
            return;
        }

        // Set team slug
        const teamSlug = (URL as ParsedDomain)?.subdomain;
        commit('setSlug', teamSlug);

        // Fetch user with latest memberships
        await dispatch('user/updateUser', null, { root: true });

        // Get team by slug
        const { data: team } = await Axios.get<ITeams>(`/teams`, {
            params: {
                slug: teamSlug,
                resellerDomain: rootState.reseller.domain
            }
        });

        // No team found, no need for additional checks
        if (!team) {
            return;
        }

        commit('setTeam', team);
        console.log('Current subdomain team', teamSlug, team);

        console.warn(
            'Checking is member with:',
            (rootGetters as any)?.[
                'user/currentResellerMemberships'
            ] as PaginatedData<IMemberships>
        );

        // Check is member of current team (by slug) under this reseller
        const isMember = (
            (rootGetters as any)?.[
                'user/currentResellerMemberships'
            ] as IMemberships[]
        )?.find(({ team }) => {
            console.info(
                `Checking team username ${(
                    team as ITeams
                ).username.toLowerCase()} matches slug ${teamSlug?.toLowerCase()}`
            );
            return (
                (team as ITeams).username.toLowerCase() ===
                teamSlug?.toLowerCase()
            );
        });
        commit('setIsMember', !!isMember);

        if (getters.isReseller && rootGetters['auth/isAuthenticated']) {
            await dispatch('reseller/fetchBrokenSubscriptions', undefined, {
                root: true
            });
        }

        // Update theming
        await dispatch('handleTheme');

        return team;
    },
    async refreshTeam({ state, commit, dispatch }) {
        // Refresh current team data
        const { data: team } = await Axios.get<ITeams>(
            `/teams/${state.team?.id}`
        );

        await commit('setTeam', team);

        dispatch('handleTheme');
    },
    async updateTeam({ state, commit, dispatch }, team) {
        if (team.logo !== state.team.logo) {
            await dispatch('fetchLogo');
        }

        await commit('setTeam', team);

        dispatch('handleTheme');
    },
    handleTheme({ dispatch, getters }) {
        // Set team if team is a reseller
        // Or if it has the branding feature
        if (
            getters.isReseller ||
            getters.plan?.featureFlags?.includes(PlanFeatureFlags.BRANDING)
        ) {
            dispatch('setTheme');
        } else {
            dispatch('reseller/setTheme', '', {
                root: true
            });
        }
    },
    setTheme({ state }) {
        // Update primary color on theme
        Vuetify.framework.theme.themes.light.primary =
            state.team?.primaryColor ?? // Set a fallback incase no reseller is fetched, this indicates an error
            `#${new Array(6)
                .fill(null)
                .map(
                    () =>
                        '0123456789ABCDEF'.split('')[
                            Math.floor(Math.random() * 16)
                        ]
                )
                .join('')}`;
    },
    async fetchLogo({ commit }) {
        const host = psl.parse(window.location.hostname);
        if (!host.error) {
            try {
                const { data } = await Axios.get(
                    `/teams/logo?slug=${host.subdomain}&resellerDomain=${host.domain}`,
                    { responseType: 'blob' }
                );
                const objectURL = URL.createObjectURL(data);
                commit('setLogoObjectURL', objectURL);
            } catch (error) {
                console.error(error);
            }
        }
    },
    async updateBillingInfo(
        { commit, dispatch, getters },
        billingInfo: ITeamBillingInfos
    ) {
        // Skip if billing info is not the current one
        if (!billingInfo.current) {
            console.log('Outdated billing info received, skip update');
            return;
        }

        // Check if plan needs an update
        if (getters.billingInfo.planId !== billingInfo.planId) {
            console.log("Team's plan received an update, refresh team data");
            // Update team to get latest data
            dispatch('refreshTeam');
            return;
        }

        // Commit billing info update with current plan attached
        commit('setBillingInfo', {
            plan: getters.billingInfo.plan,
            ...billingInfo
        });
    }
};

export const getters: GetterTree<TeamState, any> = {
    team: (state) => state.team,
    billingInfo: (state) => state.team.currentBillingInfo ?? {},
    plan: (state) => state.team.currentBillingInfo?.plan ?? {},
    isReseller: (state) => !!state.team.ofResellerId,
    isNestedReseller: (state, getters) =>
        getters.isReseller && !!state.team.resellerId,
    logo: (state: TeamState) => state.logoObjectURL,
    isMember: (state) => state.isMember,
    /** Gather data for anything related to the team's billing issue, if any */
    billingIssue: (state, getters, _rootState, rootGetters) => {
        // No issue if team is a reseller
        // Or no billing info is found
        if (state.team.ofResellerId || !getters.billingInfo) {
            return;
        }

        // On trial period billing issues are not taken into consideration
        if (
            getters.billingInfo?.trialEndDate &&
            isFuture(new Date(getters.billingInfo?.trialEndDate))
        ) {
            return;
        }

        // Get billing data from team
        const {
            hasValidPaymentMethod,
            hasUnpaidInvoices,
            stripeSubscriptionState,
            nonPaymentFlowState,
            nonPaymentFlowStateUpdatedAt
        } = getters.billingInfo as ITeamBillingInfos;

        const { isReadOnly, readOnlyReason } = state.team as ITeams;

        const hasOngoingNonPaymentFlow =
            nonPaymentFlowState === NonPaymentFlowState.ONGOING;
        const hasPausedNonPaymentFlow =
            nonPaymentFlowState === NonPaymentFlowState.PAUSED;

        // Handle billing issues, if any
        if (
            !hasValidPaymentMethod ||
            hasOngoingNonPaymentFlow ||
            hasPausedNonPaymentFlow
        ) {
            // Get if user has billing permission
            const userHasBillingPermission = rootGetters[
                'user/permissions'
            ]?.includes(Permissions['Mambo:Billing']);

            // Handle alerts for teams with only an invalid payment method
            // These teams have not reached the non-payment flow yet
            if (
                !hasValidPaymentMethod &&
                !hasUnpaidInvoices &&
                stripeSubscriptionState === StripeSubscriptionState.OK
            ) {
                const readOnlyDueToInvalidPaymentMethod =
                    readOnlyReason === ReadOnlyReason.NO_VALID_PAYMENT_METHOD;

                return {
                    type: BillingIssueType.INVALID_PAYMENT_METHOD,
                    // Invalid payment method alert is only shown to users with billing permissions
                    showAlert: userHasBillingPermission,
                    userHasBillingPermission,
                    // Read-only due to not having a payment method
                    // is possible when a team is on the post-trial flow
                    teamIsReadOnly: !!isReadOnly,
                    readOnlyDueToBilling: readOnlyDueToInvalidPaymentMethod,
                    showToEveryone: readOnlyDueToInvalidPaymentMethod
                } as BillingIssue;
            }

            // Get how many days have passed since the billing issue has been present
            const daysSince = nonPaymentFlowStateUpdatedAt
                ? differenceInDays(
                      new Date(),
                      new Date(nonPaymentFlowStateUpdatedAt)
                  )
                : undefined;

            // Track if alert is shown to all users, both as banner and full screen alert
            // First 15 days we only display a banner to owners and users with billing permission
            // If non-payment flow was paused, we only show to billing users
            const showToEveryone =
                (daysSince ?? 0) >= 15 && !hasPausedNonPaymentFlow;

            // Centralize data needed for the alert
            const billingData = {
                showAlert: showToEveryone || userHasBillingPermission,
                userHasBillingPermission,
                since: nonPaymentFlowStateUpdatedAt,
                daysSince,
                showToEveryone,
                showFullScreen: showToEveryone,
                teamIsReadOnly: !!isReadOnly,
                readOnlyDueToBilling: readOnlyReason
                    ? [
                          ReadOnlyReason.UNPAID_INVOICES,
                          ReadOnlyReason.SUBSCRIPTION_CANCELLED
                      ].includes(readOnlyReason)
                    : false,
                hasPausedNonPaymentFlow
            };

            // Find which billing error applies and return data
            switch (true) {
                case hasUnpaidInvoices:
                    return {
                        type: BillingIssueType.UNPAID_INVOICES,
                        ...billingData
                    } as BillingIssue;
                case stripeSubscriptionState ===
                    StripeSubscriptionState.CANCELLED:
                    return {
                        type: BillingIssueType.CANCELLED_SUBSCRIPTION,
                        ...billingData
                    } as BillingIssue;
                case stripeSubscriptionState === StripeSubscriptionState.BROKEN:
                    return {
                        type: BillingIssueType.BROKEN_SUBSCRIPTION,
                        ...billingData
                    } as BillingIssue;
                default:
                    // stripeSubscriptionState === StripeSubscriptionState.EMPTY
                    return {
                        type: BillingIssueType.EMPTY_SUBSCRIPTION,
                        ...billingData
                    } as BillingIssue;
            }
        }

        // No billing issue case was caught, all OK with billing
        return;
    },
    isReadOnly: (state) => !!state.team?.isReadOnly
};
