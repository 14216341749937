export default {
    item: 'variable | variables',
    variable: 'Variable: ',
    searchFields: 'name',
    deleteDialog: {
        title: 'Delete variable',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'variable from',
            p3: '?'
        }
    },
    bulkDeleteDialog: {
        title: 'Delete variable | Delete variables',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'variable from | variables from',
            p3: '?'
        }
    },
    noData: 'You have not created any variables',
    multiMatchToDelete: 'Delete 1 variable | Delete {count} variables'
};
