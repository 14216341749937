export default {
    title: 'Download team data',
    text: "You have the right to export data that belongs to your team. Data will be provided in a JSON format and does not include an export of your members' data.",
    deleteTeam: {
        title: 'Delete team',
        text: {
            isReseller:
                'You can delete your team and its data permanently. Since you are a reseller this will affect all the teams created under you, factory resetting all of their devices.',
            isNotReseller: 'You can delete your team and its data permanently.',
            trial: {
                p1: 'You may delete your team at any point during the trial. All data associated with the team will be irreversible and immediately deleted, with no option to restore. Your enterprise bind will be deleted and any enrolled devices will be reset automatically.',
                p2: "As you're currently trialling ",
                p3: ', there are no charges associated with the team or removing it at this time.'
            },
            postTrial: {
                p1: 'Your trial has ended and you have not yet set a payment method. Your team has been scheduled for deletion.',
                p2: 'If no payment is set by ',
                p3: ' your team will be irreversibly deleted. Your Android Enterprise bind will be deleted and any enrolled devices will be reset automatically.',
                p4: 'If you do not wish to continue with ',
                p5: " anymore, you may delete your team immediately, but we hope you'll reach out to the {0} support team before then to discuss your experience and allow the team to remedy any frustrations.",
                p6: 'Use the button below to delete the team immediately (non-reversible!).'
            },
            notOnTrial: {
                p1: 'Once you proceed with the delete, your subscription will be immediately paused to prevent further charges, and a 30-day countdown will begin. During this time, any team owner may cancel the deletion request, and everything will be reinstated.',
                p2: "Once the process starts, only owners will have access to the team's data in a view-only mode, this is reverted if the team deletion is cancelled. Any invoices must be paid before the deletion is fulfilled.",
                p3: 'After 30 days, the Android Enterprise bind will be deleted and all devices still enrolled will factory reset.'
            },
            cancelDelete:
                'Your team was requested to be deleted, but you can cancel it now.'
        }
    },
    dialog: {
        title: 'Delete {0} team',
        text: {
            p1: 'This action is not reversible and your subscription will be cancelled.',
            p2: 'Since this team is on trial, the deletion will be immediate.',
            p3: 'Are you sure you want to delete this team?'
        },
        cancelTitle: 'Cancel team deletion',
        cancelText:
            'Are you sure you want to cancel the deletion of this team?',
        cancelError: 'Unable to cancel at the moment'
    },
    matchToDelete: 'Delete {0}',
    matchToCancel: "I don't want to delete {0}"
};
