import Vue from 'vue';

/**
 * Page `<meta>` tag service
 */
const $pageMeta = {
    /**
     * Update document title
     * @param title Page title
     */
    setTitle(title: string) {
        document.title = title;
    },
    /**
     * Update meta tag
     * @param tagName Meta tag e.g. author, description, keywords
     * @param content Meta tag content e.g. SocialMobile
     */
    setMetaTag(tagName: string, content: string) {
        // Find existing meta tag
        const tag = document.querySelector<HTMLMetaElement>(
            `meta[name=${tagName}]`
        );

        if (tag) {
            // Update existing tag
            tag.setAttribute('content', content);
        } else {
            // Create meta tag
            const meta = document.createElement('meta');
            // Set meta content
            meta.setAttribute('content', content);
            // Append meta tag
            document.querySelector('head')?.appendChild(meta);
        }
    },
    /**
     * Set multiple meta tags
     * @param tags Tags to update in format `[tagName, tagContent]`
     *
     * Example:
     * ```ts
     * setMetaTags(['author', 'mambo EMM'], ['description', 'A new page']);
     * ```
     */
    setMetaTags(...tags: [string, string]) {
        tags.forEach(([tagName, content]) => this.setMetaTag(tagName, content));
    }
};

export default $pageMeta;

// Add $pageMeta to `this` object in Vue components
Vue.prototype.$pageMeta = $pageMeta;
