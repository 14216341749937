import { MutationTree, ActionTree, GetterTree } from 'vuex';
import Vue from 'vue';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import {
    IMemberships,
    IResellers,
    IRoles,
    ITeams,
    IUsers,
    WebSocketEvent
} from '@balloon/types';
import { updates$ } from '../plugins/updates-subject';
import { permissions$ } from '../plugins/permissions-subject';

export type SocketState = {
    socket: Socket | null;
    userUpdates$: Subscription | null;
    resellerUpdates$: Subscription | null;
    teamUpdates$: Subscription | null;
    billingInfoUpdates$: Subscription | null;
};

export const namespaced = true;

export const state = (): SocketState => ({
    socket: null,
    userUpdates$: null,
    resellerUpdates$: null,
    teamUpdates$: null,
    billingInfoUpdates$: null
});

export const mutations: MutationTree<SocketState> = {
    // Set state socket
    setSocket(state: SocketState, socket: Socket) {
        Vue.set(state, 'socket', socket);
    },
    // Close and remove socket
    close(state: SocketState) {
        // Close socket connection
        if (state.socket) {
            state.socket.close();
        }
        Vue.set(state, 'socket', null);

        // Close update subscriptions
        if (state.userUpdates$) {
            state.userUpdates$.unsubscribe();
            Vue.set(state, 'userUpdates$', null);
        }
        if (state.resellerUpdates$) {
            state.resellerUpdates$.unsubscribe();
            Vue.set(state, 'resellerUpdates$', null);
        }
        if (state.teamUpdates$) {
            state.teamUpdates$.unsubscribe();
            Vue.set(state, 'teamUpdates$', null);
        }
    },
    // Watch for user updates
    setUserUpdateHandler(state: SocketState, sub: Subscription) {
        Vue.set(state, 'userUpdates$', sub);
    },
    // Watch for reseller updates
    setResellerUpdateHandler(state: SocketState, sub: Subscription) {
        Vue.set(state, 'resellerUpdates$', sub);
    },
    // Watch for reseller updates
    setTeamUpdateHandler(state: SocketState, sub: Subscription) {
        Vue.set(state, 'teamUpdates$', sub);
    },
    // Watch for billing info updates
    setBillingInfoUpdateHandler(state: SocketState, sub: Subscription) {
        Vue.set(state, 'billingInfoUpdates$', sub);
    },
    // Broadcast websocket updates
    broadcastUpdate(_state: SocketState, data: WebSocketEvent) {
        updates$.next(data);
    },
    broadcastPermissions(
        _state: SocketState,
        data: WebSocketEvent<IRoles | IMemberships>
    ) {
        permissions$.next(data);
    },
    // Update socket auth token
    updateSocketToken(state: SocketState, token: string) {
        if (state.socket) {
            (state.socket.io.opts.query as { token: string }).token = token;
        }
    }
};

export const actions: ActionTree<SocketState, any> = {
    init({ rootState, state, commit, rootGetters, dispatch }) {
        // Create socket if user is authenticated
        const {
            isAuthenticated = false,
            tokens: { token }
        } = rootState.auth;
        if (isAuthenticated) {
            console.log('Starting Socket.IO');

            // Connect with the auth token
            let { socket } = state;
            if (!socket) {
                console.log('No socket initiated, creating now');
                socket = io({
                    query: {
                        token,
                        type: 'auth'
                    },
                    path: '/api/websocket',
                    reconnectionAttempts: 3,
                    reconnectionDelay: 30 * 1000 // 30 seconds
                });
            }

            // Log connection
            socket.on('connect', () => console.log('New socket.io connection'));

            // Set new token on reconnect
            socket.io.on('reconnect_attempt', () => {
                console.log(
                    'Reconnect_attempt - Socket Token:',
                    socket!.io.opts.query
                );
            });

            // Listen for updates
            socket.on('updates', (data: WebSocketEvent) => {
                console.log('Received Websockets `updates` message', data);

                commit('broadcastUpdate', data);
            });

            // Subscribe to updates on current team
            socket.emit(
                'updates',
                rootGetters?.['team/team']?.id,
                (data: string) =>
                    console.log('WebSockets update emit response:', data)
            );

            // Listen for permission changes
            socket.on(
                'permissions',
                (data: WebSocketEvent<IRoles | IMemberships>) => {
                    console.log(
                        'Received Websockets `permissions` message',
                        data
                    );
                    if (data.payload) {
                        switch (data.type) {
                            case 'Memberships':
                                commit('broadcastPermissions', data);
                                commit('user/setRole', data.payload, {
                                    root: true
                                });
                                break;

                            case 'Roles':
                            default:
                                commit('broadcastPermissions', data);
                                commit('user/setPermissions', data.payload, {
                                    root: true
                                });
                                break;
                        }
                    }
                }
            );

            // Subscribe to permissions
            socket.emit('permissions', 'Subscribe', (data: any) => {
                console.log('WebSockets permissions emit response:', data);
            });

            // Log Socket exceptions
            socket.on('exception', (err: any) => {
                console.warn('Socket Exception Thrown:', err);

                // Try refreshing token on exception
                dispatch('auth/safeRefresh', undefined, { root: true });
            });

            // Log connection error
            socket.on('error', (err: any) => {
                console.log('Connection error:', err.message);
            });

            // Log Socket connection error
            socket.on('connect_error', (err: any) => {
                console.log('Socket conn error:', err);
                setTimeout(() => {
                    // the disconnection was initiated by the server, need to reconnect manually
                    socket!.connect();
                }, 1000);
            });

            // Commit socket
            commit('setSocket', socket);

            // Setup user updates listener
            if (!state.userUpdates$) {
                commit(
                    'setUserUpdateHandler',
                    updates$
                        .pipe(
                            filter((ev: WebSocketEvent<any>) =>
                                ev.type.startsWith('Users')
                            ),
                            map(
                                ({
                                    payload: details
                                }: WebSocketEvent<IUsers>) =>
                                    commit(
                                        'user/setUserDetails',
                                        { details },
                                        { root: true }
                                    )
                            )
                        )
                        .subscribe()
                );
            }

            // Setup reseller updates listener
            if (!state.resellerUpdates$) {
                commit(
                    'setResellerUpdateHandler',
                    updates$
                        .pipe(
                            filter((ev: WebSocketEvent<any>) =>
                                ev.type.startsWith('Resellers')
                            ),
                            map(
                                ({
                                    payload: details
                                }: WebSocketEvent<IResellers>) => {
                                    // Update team in store if the current team is a reseller whose payload received refers to it
                                    if (
                                        rootGetters['team/isReseller'] &&
                                        rootGetters['team/team'].id ===
                                            details.tenantId
                                    ) {
                                        dispatch(
                                            'team/refreshTeam',
                                            undefined,
                                            {
                                                root: true
                                            }
                                        );
                                        // Secondary resellers don't need the additional updateReseller
                                        // A root reseller has a resellerId: null
                                        if (
                                            rootGetters['team/team'].resellerId
                                        ) {
                                            return;
                                        }
                                    }
                                    // Update the team's reseller
                                    return commit(
                                        'reseller/updateReseller',
                                        details,
                                        {
                                            root: true
                                        }
                                    );
                                }
                            )
                        )
                        .subscribe()
                );
            }

            // Setup team updates listener
            if (!state.teamUpdates$) {
                commit(
                    'setTeamUpdateHandler',
                    updates$
                        .pipe(
                            filter((ev: WebSocketEvent<any>) =>
                                ev.type.startsWith('Teams')
                            ),
                            map(
                                ({
                                    payload: details
                                }: WebSocketEvent<ITeams>) =>
                                    dispatch('team/updateTeam', details, {
                                        root: true
                                    })
                            )
                        )
                        .subscribe()
                );
            }

            // Handle billing info updates
            if (!state.billingInfoUpdates$) {
                commit(
                    'setBillingInfoUpdateHandler',
                    updates$
                        .pipe(
                            filter(
                                (event: WebSocketEvent<any>) =>
                                    event?.type.startsWith(
                                        'TeamBillingInfos:update'
                                    ) ||
                                    event?.type.startsWith(
                                        'TeamBillingInfos:create'
                                    )
                            ),
                            map(({ payload }) => {
                                // Set payload on store data
                                dispatch('team/updateBillingInfo', payload, {
                                    root: true
                                });
                            })
                        )
                        .subscribe()
                );
            }
        }
    },
    updateToken({ commit }, token: string) {
        commit('updateSocketToken', token);
    },
    close({ commit }) {
        commit('close');
    }
};

export const getters: GetterTree<SocketState, any> = {
    socket: (state) => state.socket
};
